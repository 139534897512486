// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rH d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rJ d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_rK d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rL d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rM d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rN d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rP d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rQ x_rQ";
export var heroExceptionNormal = "s_rR x_rR";
export var heroExceptionLarge = "s_rS x_rS";
export var Title1Small = "s_rT x_rT x_sf x_sg";
export var Title1Normal = "s_rV x_rV x_sf x_sh";
export var Title1Large = "s_rW x_rW x_sf x_sj";
export var BodySmall = "s_rX x_rX x_sf x_sy";
export var BodyNormal = "s_rY x_rY x_sf x_sz";
export var BodyLarge = "s_rZ x_rZ x_sf x_sB";